*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
    /* transition: all .3s linear; */
}
p,h1,h2,h3,h4,h5,h6{
  margin: 0;
}

html{
  font-family: 'EnnVisions' !important;
}

body{
  font-family: "EnnVisions" !important;
  background-color: lightgray;
}

.ant-modal-content{
  border-radius: 7px !important;
}

@font-face {
  font-family: "EnnVisions";
  src: local("SFUIText-Regular"),
      url("./Assets/fonts/Overpass-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "EnnVisionsBold";
  src: local("SFUIText-Regular"),
      url("./Assets/fonts/Overpass-Medium.ttf") format("truetype");
}

.drawer-menu a{
  color: black !important;
}

.ant-input[disabled]{
  background-color: transparent !important;
  color: black !important;
}
.hf-link{
  color: black;
  width: auto;
}
@media screen and (max-width : 768px) {
  .content-table-main{
    display: none;
  }
}

.table-search-container{
  display: flex;
  justify-content: space-between;
  align-items: center;
}